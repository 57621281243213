@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");
.my-footer {
  color: var(--color-info-light);
  /* max-width: 1400px; */
  padding: 20px 50px;
  background: #14042b;
}
.footer-info {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.footer-img {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
}
.footer-img h4 {
  margin-bottom: 0;
  margin-left: 5px;
}
.footer-logo {
  width: 30px;
}
.my-footer a {
  color: var(--color-info-light);
  margin-right: 10px;
}
.foot-name {
  margin-top: 5px;
}
.all-links {
  margin-bottom: 20px;
}
.all-social-links {
  margin-bottom: 20px;
}

.navbar-brand {
  height: 36px;
  position: fixed;
  top: 25px;
  left: 70px;
  z-index: 3333;
  width: auto;
  display: inline-block;
  margin: 0;
  padding: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.navbar-brand::before {
  position: absolute;
  content: "";
  top: -13px;
  left: 50%;
  width: 80px;
  transform: translateX(-50%);
  height: calc(100% + 26px);
  opacity: 0;
  z-index: -1;
  background-image: var(--color-special-color);
  animation: border-transform 10s linear infinite alternate forwards;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap {
  position: fixed;
  right: 20px;
  bottom: 20px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);
  z-index: 70;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap i {
  font-size: 30px;
  position: absolute;
  bottom: 8px;
  right: 8px;
}
.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.progress-wrap:hover::before {
  opacity: 1;
}
.progress-wrap svg path {
  fill: none;
}
.progress-wrap svg.progress-circle path {
  stroke: var(--grey);
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.all-social-links {
  display: flex;
}
.footer2-social {
  margin-right: 10px;
}
.footer2-social i {
  font-size: 20px;
}
.footer2-social:hover i {
  color: #e51681 !important;
}
.copywrite {
  width: 100%;
  display: flex;
  padding: 15px 0 0 0;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}
.buy-coffee {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  cursor: pointer;
  z-index: 200;
  left: 10px;
  bottom: 20px;
  border-radius: 50%;
}
.buy-coffee img {
  width: 50px;
}

/* Responsive */
@media only screen and (max-width: 425px) {
  .progress-wrap {
    right: 15px;
  }
}
@media only screen and (max-width: 390px) {
  .footer-info {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* light theme */
.light .my-footer {
  background: #5414ac;
  color: #fff !important;
}
.light .copywrite p {
  color: #fff !important;
}
.light .progress-wrap {
  background: #010116;
}
.light .progress-wrap::after {
  color: aliceblue !important;
}
